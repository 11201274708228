<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        a)
        <stemble-latex content="$\ce{SO2}$" />
        gas at a pressure of
        <number-value :value="Pi" unit="\text{atm}" />
        is heated from
        <stemble-latex content="$0.0^{\circ}\text{C}$" />
        to
        <number-value :value="Tf" unit="^{\circ}\text{C}" />
        and is simultaneously compressed to
        <stemble-latex :content="volReduction" />
        of its original volume. Determine the final pressure (in atm).
      </p>

      <calculation-input
        v-model="inputs.Pf"
        class="mb-5"
        prepend-text="$\text{Pressure:}$"
        append-text="$\text{atm}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) If the
        <stemble-latex content="$\ce{SO2}$" />
        has a mass of
        <stemble-latex content="$14.6\,\text{g}$" />
        , determine the <b>final</b> volume of the system described in part a) (in L).
      </p>

      <calculation-input
        v-model="inputs.Vol"
        prepend-text="$\text{Volume:}$"
        append-text="$\text{L}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question265',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Pf: null,
        Vol: null,
      },
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    },
    volReduction() {
      if (this.versionNumber.value === 1) {
        return 'one-third';
      } else if (this.versionNumber.value === 2) {
        return 'one-quarter';
      } else if (this.versionNumber.value === 3) {
        return 'one-fifth';
      } else {
        return 'ERROR';
      }
    },
    Pi() {
      return this.taskState.getValueBySymbol('Pi').content;
    },
    Tf() {
      return this.taskState.getValueBySymbol('Tf').content;
    },
  },
};
</script>
